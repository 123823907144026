<template>
	<div :class="['base-button', btnClass]" :style="customStyle" @click="handleClick">
		<slot>默认按钮</slot>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: 'clear',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			btnClass: '',
		};
	},
	computed: {
		customStyle() {
			if (this.disabled) {
				return {
					cursor: 'not-allowed',
					opacity: 0.6,
				};
			} else {
				return {};
			}
		},
	},
	created() {
		const { type } = this;
		const typeArr = ['clear', 'add', 'delete', 'cancel', 'success'];
		if (typeArr.includes(type)) {
			this.btnClass = type;
		}
	},
	methods: {
		handleClick() {
			if (this.disabled) return;
			this.$emit('click');
		},
	},
};
</script>

<style lang="scss" scoped>
$btnAddBgColor: #378aef;
$btnAddActiveBgColor: #5da2f7;
$btnDeleteBgColor: #f45656;
$borderColor: #dee0e5;
$defaultColor: #41474e;
$successColor: #67c23a;

.base-button {
	height: 34px;
	line-height: 34px;
	padding: 0 26px;
	display: inline-block;
	box-sizing: border-box;
	text-align: center;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: $btnAddBgColor;
	background-color: #fff;
	border: 1px solid $btnAddBgColor;
	border-radius: 4px;
	user-select: none;
	cursor: pointer;
	&:hover {
		background-color: #f5f9fd;
		border-color: $btnAddBgColor;
	}

	&.add {
		color: #fff;
		background-color: $btnAddBgColor;
		border: 0;
		&:hover {
			background-color: $btnAddActiveBgColor;
		}
	}

	&.delete {
		color: #fff;
		background-color: $btnDeleteBgColor;
		border: 0;
		&:hover {
			background-color: #fd6d6d;
		}
	}

	&.cancel {
		color: $defaultColor;
		border-color: $borderColor;
		&:hover {
			background-color: #f5f9fd;
			border-color: #f7f9fa;
		}
	}

	&.success {
		color: #fff;
		background-color: $successColor;
		border: 0;
		&:hover {
			background-color: #86cf62;
		}
	}
}
</style>
