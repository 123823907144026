<template>
	<div class="container">
		<div class="global-dialog-container">
			<div class="animation-container">
				<div
					ref="globalDialog"
					class="global-dialog to-animation"
					:class="{ 'fullScreen-dialog': fullScreen }"
					:style="[
						{
							width: fullScreen ? '100vw' : width,
							height: fullScreen ? '100vh' : height,
						},
					]"
				>
					<div class="global-dialog-title" :class="{ 'fullScreen-title': fullScreen }">
						<slot name="title">
							{{ title }}
						</slot>
						<div class="operation">
							<div v-if="isFull" class="operation-full" @click="toggleFull">
								<i
									:class="{
										'iconfont icon-suoxiao': fullScreen,
										'iconfont icon-fangda': !fullScreen,
									}"
								></i>
							</div>
							<div class="operation-close" @click="close">
								<i class="el-icon-close"></i>
							</div>
						</div>
					</div>
					<div
						class="global-dialog-content global-overflow"
						:class="{ 'fullScreen-content': fullScreen, unfooter: !footer }"
					>
						<slot name="content">default content</slot>
					</div>
					<div
						v-if="footer"
						class="global-dialog-footer"
						:class="{ 'fullScreen-footer': fullScreen, 'footer-right': direction === 'right' }"
					>
						<slot name="footer">
							<BaseButton v-if="showCancelBtn" class="operations-btn" type="cancel" @click="close">
								{{ cancelBtnText }}
							</BaseButton>
							<BaseButton class="operations-btn" type="add" @click="save">{{ confirmBtnText }}</BaseButton>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import BaseButton from '@/components/BaseButton';
export default {
	name: 'AppVDialog',
	components: { BaseButton },
	props: {
		footer: {
			type: Boolean,
			default: () => {
				return true;
			},
		},
		isFull: {
			type: Boolean,
			default: () => {
				return false;
			},
		},
		title: {
			type: String,
			default: () => {
				return '';
			},
		},
		direction: {
			type: String,
			default: () => {
				return 'center';
			},
		},
		width: {
			type: String,
			default: () => {
				return '914px';
			},
		},
		height: {
			type: String,
			default: () => {
				return '702px';
			},
		},
		confirmBtnText: {
			type: String,
			default: () => {
				return '保存';
			},
		},
		cancelBtnText: {
			type: String,
			default: () => {
				return '取消';
			},
		},
		showCancelBtn: {
			type: Boolean,
			default: () => {
				return true;
			},
		},
	},
	data() {
		return {
			fullScreen: false,
		};
	},
	mounted() {},
	methods: {
		toggleFull() {
			this.fullScreen = !this.fullScreen;
		},
		close() {
			this.$emit('close');
		},
		save() {
			this.$emit('save');
		},
	},
};
</script>
<style lang="scss" scoped>
.global-dialog-container {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 2000;
	.animation-container {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.global-dialog {
			background: #ffffff;
			border-radius: 8px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
			.global-dialog-title {
				position: relative;
				background: rgba(55, 138, 239, 0.08);
				border-radius: 8px 8px 0px 0px;
				height: 50px;
				line-height: 50px;
				font-size: 16px;
				font-weight: bold;
				color: #272e3b;
				padding: 0 20px;
				.operation {
					position: absolute;
					height: 20px;
					top: 15px;
					right: 20px;
					display: flex;
					justify-content: right;
					align-items: center;
					.operation-close {
						display: flex;
						cursor: pointer;
						font-size: 24px;
						color: #999999;
					}
					.operation-full {
						i {
							cursor: pointer;
							margin-right: 12px;
							color: #999999;
							font-size: 20px;
						}
					}
				}
			}
			.global-dialog-content {
				width: 100%;
				height: calc(100% - 106px);
				padding: 10px 24px 0 24px;
			}
			.global-overflow {
				overflow: auto;
			}
			.unfooter {
				height: calc(100% - 64px);
				overflow: hidden;
			}
			.global-dialog-footer {
				width: 100%;
				height: 32px;
				margin-top: 12px;
				display: flex;
				justify-content: center;
				padding: 0 20px;
			}
			.footer-right {
				justify-content: flex-end;
			}
		}
		.fullScreen-dialog {
			border-radius: 0;
		}
		.fullScreen-content {
			padding: 10px 44px 24px 24px !important;
		}
		.fullScreen-title {
			background: rgba(55, 138, 239, 0.08) !important;
		}
		.fullScreen-footer {
			position: absolute;
			bottom: 0;
			height: 64px !important;
			display: flex;
			align-items: center;
			background: #fff;
			box-shadow: -6px 0px 15px 0px rgba(109, 125, 146, 0.29);
		}
	}
	.to-animation {
		animation: scales 0.3s ease;
		animation-fill-mode: forwards;
	}
}
@keyframes scales {
	0% {
		transform: translate3d(0, -20px, 0);
		opacity: 0;
	}
	100% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}
</style>
