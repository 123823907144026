<template>
	<div class="myDatePicker">
		<el-date-picker
			v-bind="$attrs"
			ref="datePicker"
			@change="change"
			v-model="innerValue"
			popper-class="date_picker"
			:picker-options="pickerOptions"
		>
			<template v-for="(value, name) in $slots" #[name]="slotData">
				<slot :name="name" v-bind="slotData || {}"></slot>
			</template>
		</el-date-picker>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			default: null,
		},
	},
	data() {
		return {
			innerValue: this.value,
			pickerOptions: {
				shortcuts: [
					{
						text: '此刻',
						onClick(picker) {
							picker.$emit('pick', new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000));
						},
					},
					{
						text: '昨天',
						onClick(picker) {
							const date = new Date();
							date.setTime(new Date(date.getTime() + new Date().getTimezoneOffset() * 60000) - 3600 * 1000 * 24);
							picker.$emit('pick', date);
						},
					},
					{
						text: '一周前',
						onClick(picker) {
							const date = new Date();
							date.setTime(new Date(date.getTime() + new Date().getTimezoneOffset() * 60000) - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', date);
						},
					},
				],
			},
		};
	},
	computed: {},
	created() {
		// console.log(111111111, this.$attrs); // 传入值
		// console.log(22222222, this.$slots); // 传入插槽
	},
	watch: {
		value(newVal) {
			// console.log('🚀 ~ value ~ newVal:', newVal);
			this.innerValue = newVal;
		},
		innerValue(newVal) {
			this.$emit('input', newVal);
		},
	},
	mounted() {
		// 把mydatePicker的方法提到当前实例，方便外部调用
		// console.log(this.$refs.datePicker);
		const entries = Object.entries(this.$refs.datePicker);
		for (let [key, value] in entries) {
			this[key] = value;
		}
	},
	methods: {
		change(...data) {
			this.$emit('change', ...data);
			this.$emit('input', data[0]); // 更新父组件的 v-model
		},
	},
};
</script>

<style lang="scss" scoped>
</style>
// 注意：日期弹窗的样式是和vue根组件同级，所以页面额外再添加一个style样式
<style lang="scss">
.el-date-editor.el-input {
	width: 180px !important;
}
.date_picker {
	.el-picker-panel__footer {
		.el-button--text {
			display: none !important;
		}
	}
}
</style>