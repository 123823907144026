// 当前页面宽度相对于1920宽的缩放比例，可根据自己需要修改，一般为设计稿的宽度。
const DEFAULT_WIDTH = 1920;
// 设置大一些，避免pc浏览器最小字体的限制
const baseSize = 16;
const nowClientWidth = document.documentElement.clientWidth;

function setRem() {
	// 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
	const scale = nowClientWidth / DEFAULT_WIDTH;
	// 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
	document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
}
setRem();
window.onresize = function () {
	setRem();
};

// 当前视口宽度

// 换算方法
function nowSize(val) {
	return val * (nowClientWidth / DEFAULT_WIDTH);
}
export { nowSize };
