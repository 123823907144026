import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@arcgis/core/assets/esri/themes/light/main.css';
import Vue from 'vue';
// global css
import 'mapbox-gl/dist/mapbox-gl.css';
import '@/styles/index.scss';
import '@/styles/mapbox.scss';
import '@/styles/animate.css';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets
import ElementUI, { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '@/icons/index.js'; // icon

import bus from './bus.js';

// 引入animate 动画
import animated from 'animate.css';

import App from './App.vue';
import router from '@/router';
import store from '@/store';
import axiosPlugin from '@/plugins/axios';
import VDialog from '@/components/VDialog';
import BaseButton from '@/components/BaseButton';
import mydatePicker from '@/components/MyComponents/mydatePicker';
// rem
import '@/utils/rem';

// v-dialogDrag自定义指令
import '@/utils/dialogDrag.js';

Vue.config.productionTip = false;
Vue.use(animated);
Vue.use(ElementUI);
Vue.use(axiosPlugin);
Vue.component('VDialog', VDialog);
Vue.component('BaseButton', BaseButton);
Vue.component('mydatePicker', mydatePicker);
Vue.use(mydatePicker);
Vue.prototype.$message = Message;
Vue.prototype.$bus = bus;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
