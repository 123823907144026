<template>
	<div id="app">
		<!-- <app-header /> -->
		<router-view />
	</div>
</template>

<script>
export default {
	components: {
		appHeader: () => import('./layout/components/header'),
	},
};
</script>
<style>
#app {
	font-family: Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Arial, sans-serif;
	width: 100%;
	height: 100%;
}
.esri-view-surface--inset-outline:focus::after {
	outline: none !important;
}
</style>
