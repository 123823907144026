import Cookies from "js-cookie";
const TokenKey = "Admin-Token";
const AISTokenKey = "AIS-Token";
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setAISToken(token) {
  return Cookies.set(AISTokenKey, token);
}
export function getAISToken() {
  return Cookies.get(AISTokenKey);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getCookie(key) {
  return Cookies.get(key);
}

export function setCookieInfo(key, value, config = {}) {
  return Cookies.set(key, value, config);
}

export function removeCookieInfo(key) {
  return Cookies.remove(key);
}
