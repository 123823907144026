import axios from 'axios';
import { Message } from 'element-ui';
import router from '@/router/index';
import { getToken } from '@/api/cookies';
// import store from '@/store';
export default {
	install(Vue) {
		const service = axios.create({
			baseURL: process.env.VUE_APP_BASE_API,
			timeout: 180 * 1000, 
			withCredentials: false,
		});
		service.interceptors.request.use(
			(config) => {
				if (config.url.includes('/dev/seaweather/')) {
					config.headers['x-api-key'] = 'eMLD7MYjfm2NI9u2Xckk43NDjYcg2v223jwZEqy1';
				}
				config.headers.Authorization = getToken();
				return config;
			},
			(error) => {
				console.log(error); // for debug
				return Promise.reject(error);
			},
		);

		service.interceptors.response.use(
			(response) => {
				let routeSecret = response.headers?.token;
				if (routeSecret) {
					sessionStorage.setItem('Route-Secret', routeSecret);
				}
				const res = response.data;
				// code可能返回字符串的200 = =!
				if (res.code && res.code !== 200) {
					if (res.code === 401 || res.code === 406 || (res.code === 403 && res.data == 'token 错误')) {
						Message({
							message: '登录已过期，请重新登录',
							type: 'error',
							duration: 1000,
						});
						console.log(response, '00000');
						router.push('/login');
						// store.dispatch('user/logout', { type: 1 });
					} else {
						// Message({
						// 	message: res.message || '数据服务接口异常',
						// 	type: 'error',
						// 	duration: 3 * 1000,
						// });
					}
					return res || null;
				} else {
					return res || null;
				}
				// const res = response.data;
				// if (res?.code !== 200 || (statusinfo)) {
				// 	if (res.code === 10024 || res.code === 10027) {
				// 		Message({
				// 			message: '登录已过期，请重新登录',
				// 			type: 'error',
				// 			duration: 1000,
				// 		});
				// 		// setTimeout(() => {
				// 		// 	store.dispatch('user/logout');
				// 		// }, 100);
				// 	}
				// 	return Promise.reject(new Error(res.message || 'Error'));
				// } else {
				// 	return res;
				// }
			},
			(error) => {
				console.log('err：' + error); // for debug
				Message({
					message: '暂无数据～',
					type: 'error',
					duration: 5 * 1000,
				});
				return Promise.reject(error);
			},
		);
		Vue.axios = service;
		Object.defineProperties(Vue.prototype, {
			$axios: {
				get() {
					return service;
				},
			},
		});
	},
};
