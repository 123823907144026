var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"global-dialog-container"},[_c('div',{staticClass:"animation-container"},[_c('div',{ref:"globalDialog",staticClass:"global-dialog to-animation",class:{ 'fullScreen-dialog': _vm.fullScreen },style:([
					{
						width: _vm.fullScreen ? '100vw' : _vm.width,
						height: _vm.fullScreen ? '100vh' : _vm.height,
					},
				])},[_c('div',{staticClass:"global-dialog-title",class:{ 'fullScreen-title': _vm.fullScreen }},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]}),_c('div',{staticClass:"operation"},[(_vm.isFull)?_c('div',{staticClass:"operation-full",on:{"click":_vm.toggleFull}},[_c('i',{class:{
									'iconfont icon-suoxiao': _vm.fullScreen,
									'iconfont icon-fangda': !_vm.fullScreen,
								}})]):_vm._e(),_c('div',{staticClass:"operation-close",on:{"click":_vm.close}},[_c('i',{staticClass:"el-icon-close"})])])],2),_c('div',{staticClass:"global-dialog-content global-overflow",class:{ 'fullScreen-content': _vm.fullScreen, unfooter: !_vm.footer }},[_vm._t("content",function(){return [_vm._v("default content")]})],2),(_vm.footer)?_c('div',{staticClass:"global-dialog-footer",class:{ 'fullScreen-footer': _vm.fullScreen, 'footer-right': _vm.direction === 'right' }},[_vm._t("footer",function(){return [(_vm.showCancelBtn)?_c('BaseButton',{staticClass:"operations-btn",attrs:{"type":"cancel"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.cancelBtnText)+" ")]):_vm._e(),_c('BaseButton',{staticClass:"operations-btn",attrs:{"type":"add"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.confirmBtnText))])]})],2):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }