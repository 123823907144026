import Vue from 'vue';
import VueRouter from 'vue-router';
import { getToken } from '@/api/cookies';

Vue.use(VueRouter);
const whiteList = ['/login'];
const routes = [
	{
		path: '/',
		name: 'home',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/index.vue'),
	},
	{
		path: '/monitoring',
		name: 'Monitoring',
		component: () => import('../views/monitoring/index.vue'),
	},
];
const router = new VueRouter({
	routes,
});
router.beforeEach(function (to, from, next) {
	const hasToken = getToken();
	console.log();
	if (hasToken) {
		if (to.path === 'login') {
			next({ path: '/login' });
			return;
		} else {
			next();
		}
	} else {
		if (whiteList.indexOf(to.path) !== -1) {
			// in the free login whitelist, go directly
			next();
		} else {
			// other pages that do not have permission to access are redirected to the login page.
			next(`/login?redirect=${to.path}`);
		}
	}
});

export default router;
